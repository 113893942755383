import * as React from "react"
import { FiInfo } from "react-icons/fi";
import { getParameterByName } from "../utils";

import Layout from "../components/layout"
import Seo from "../components/seo"
const siteRef = getParameterByName('ref');

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <section className="bg-gray-100">
      <div className="max-w-4xl mx-auto py-12">
        <div className="bg-white shadow-xl rounded-md">
          <div className="px-10 py-6 bg-gradient-to-bl from-sky-500 to-blue-700 rounded-t-md">
            <h1 className="text-white text-3xl mb-0.5">Privacy Policy</h1>
            <p className="text-white text-sm text-opacity-50"><span className="font-semibold">Last updated:</span> 26/05/2021</p>
          </div>
          <div className="prose px-10 py-8 max-w-full">
            {siteRef === "mockupclips" && (
                <div className="flex bg-gray-100 p-4 rounded-md text-sm"><FiInfo className="hidden md:block mr-1.5 mt-0.5 opacity-60" /><p style={{margin: 0}}>The <a href="https://mockupclips.com">mockupclips.com</a> website is a product of Edit Square LTD and carries the same privacy policy as below:</p></div>
            )}
            <p>The <a href="https://editsquare.com">www.editsquare.com</a> website (the “<strong>Website</strong>”) is operated by Edit Square Ltd, company registration number 09971898 with registered office at 71-75 Shelton Street, Covent Garden, London WC2H 9JQ (“<strong>We</strong>”, “<strong>Us</strong>” or “<strong>EditSquare</strong>”). Our ICO registration number is […].</p>
            <p>We take your privacy very seriously and we ask that you read this Privacy Policy carefully as it contains important information on:</p>
            <ul><li>the personal information we collect about you,</li><li>what we do with your information, and</li><li>who your information might be shared with.</li></ul>
            <h2><strong>Who we are</strong></h2>
            <p>EditSquare are a “data controller” for the purposes of the Data Protection Act 2018 and the General Data Protection Regulation EU 2016/679 (the “<strong>GDPR</strong>”), (i.e. we are responsible for, and control the processing of, your personal information).</p>
            <h2><strong>What information we collect?</strong></h2>
            <h4>Personal information provided by you</h4>
            <p>We may collect personal information about you (such as your name, address, telephone number, email address, payment card details etc.) when you use our Website, register with us or purchase products or services from us. We may also collect personal information when you contact us, send us feedback or post material to the Website.&nbsp;</p>
            <p>We may also collect information that your browser sends us whenever you visit our Website. This data may include information such as your computer’s IP address, browser type, browser version, the pages of our Website that you visit and other statistics relating to your use of the Website. This information may be collected in conjunction with third party services such as Google Analytics. Please refer to the ‘Use of cookies’ section below.</p>
            <h4>Personal information provided by third parties</h4>
            <p>It is possible that we may receive information about you from a third-party source. We will only accept that information if we have evidence that you have consented for the personal information to be passed to us or it is passed pursuant to another legal basis under the GDPR.</p>
            <h4>Personal information about other individuals</h4>
            <p>If you give us information on behalf of someone else, you confirm that the other person has appointed you to act on his/her behalf and has agreed that you can:</p>
            <ul><li>give consent on his/her behalf to the processing of his/her personal data;</li><li>receive on his/her behalf any data protection notices; and</li><li>give consent to the transfer of his/her personal data abroad.</li></ul>
            <h4>Sensitive personal information</h4>
            <p>It is very unlikely that we will ask you to provide sensitive personal information. If we request such information, we will explain why we are requesting it and how we intend to use it.</p>
            <p>Sensitive personal information includes information relating to your ethnic origin, your political opinions, your religious beliefs, whether you belong to a trade union, your physical or mental health or condition, your sexual life, and whether you have committed a criminal offence.</p>
            <p>We will only collect your sensitive personal information with your explicit consent.</p>
            <h4>Monitoring and recording communications</h4>
            <p>We may monitor and record communications with you (such as telephone conversations and emails) for the purpose of performing the services we offer to our clients, quality assurance, training, fraud prevention and regulatory compliance.</p>
            <h4>Use of cookies</h4>
            <p>A cookie is a small text file which is placed onto your computer (or other electronic device) when you access our Website. We use cookies on this Website to:&nbsp;</p>
            <ul><li>keep track of any products or services you may wish to purchase;</li><li>store project information and your likely currency preference based on your location;</li><li>provide you with a live chat and support service;</li><li>recognise you whenever you visit this Website (this speeds up your access to the Website as you do not have to log in each time);</li><li>obtain information about your preferences, online movements and use of the internet;</li><li>carry out research and statistical analysis to help improve our content and services and to help us better understand our visitor and customer requirements and interests;</li><li>target our marketing and advertising campaigns more effectively by providing interest-based advertisements that are personalised to your interests; and</li><li>make your online experience more efficient and enjoyable.</li></ul>
            <p>The information we obtain from our use of cookies will not usually contain your personal data. Although we may obtain information about your computer or other electronic device such as your IP address, your browser and/or other internet log information, this will not usually identify you personally. In certain circumstances we may collect personal information about you—but only where you voluntarily provide it (e.g. by completing an online form) or where you purchase services from us.</p>
            <p>In most cases we will need your consent in order to use cookies on this Website. The exception is where the cookie is essential in order for us to provide you with a service you have requested (e.g. to enable you to purchase services from us).</p>
            <p>There is a notice on our home page which describes how we use cookies and which also provides a link to this Privacy Policy. If you use our Website after this notification has been displayed to you, we will assume that you consent to our use of cookies for the purposes described in this Privacy Policy.</p>
            <p>We may work with third party suppliers who may also set cookies on our Website. These third-party suppliers are responsible for the cookies they set on our Website. If you want further information, please go to the website of the relevant third party.&nbsp;</p>
            <p>If you do not want to accept cookies, you can change your browser settings so that cookies are not accepted. If you do this, please be aware that you may lose some of the functionality of this Website. For further information about cookies and how to disable them, please go to the Information Commissioner’s webpage on cookies: <a href="http://ico.org.uk/for-the-public/online/cookies/">https://ico.org.uk/for-the-public/online/cookies/</a>.</p>
            <h2><strong>How will we use the information about you?</strong></h2>
            <p>We collect information about you so that we can:</p>
            <ul><li>identify you and manage the account you hold with us;</li><li>process your order or carry out obligations arising from any contract(s) entered into between you and us;</li><li>conduct research, statistical analysis and behavioural analysis;</li><li>carry out customer profiling and analyse your purchasing preferences;</li><li>if you agree, let you know about other products or services that may be of interest to you—see 'Marketing’ section below;</li><li>detect and prevent fraud;</li><li>customise our Website and its content to your particular preferences;</li><li>provide you with a live chat and support service;</li><li>notify you of any changes to our Website or to our services that may affect you;</li><li>carry out security vetting; and</li><li>improve our services and notify you about changes to these services.</li></ul>
            <h2><strong>Marketing</strong></h2>
            <p>Only where you have provided us with specific, informed and unambiguous consent shall we provide you with marketing materials by the mechanism(s) you have consented to (e.g. email). We will only provide you with marketing materials relating to features that you have explicitly consented to.&nbsp;</p>
            <p>If you have consented to receive such marketing from us, you can opt out at any time. See 'What rights do you have?’ below for further information.</p>
            <h2><strong>Who your information might be shared with</strong></h2>
            <p>We may disclose your personal data to:</p>
            <ul><li>our content providers as part of the service offered, but only with your explicit consent or pursuant to another legal basis under the GDPR;</li><li>our service providers pursuant to strict data processing agreements that protect your personal data to the same or higher standards than we treat it;</li><li>law enforcement agencies in connection with any investigation to help prevent unlawful activity;</li><li>a court of law or regulator where we are under a duty to disclose or share your personal data in order to comply with a legal or regulatory obligation; and</li><li>a prospective purchaser of the Edit Square business, but only subject to very strict confidentiality terms.</li></ul>
            <p>Rest assured that we will never pass your information to a third party outside of the categories above without your explicit consent.</p>
            <h2><strong>Keeping your data secure</strong></h2>
            <p>We will use technical and organisational measures to safeguard your personal data, for example:</p>
            <ul><li>access to your account is controlled by a password and username that are unique to you;</li><li>we store your personal data on secure servers; and</li><li>we only use the most secure payment providers to process your payment and never store your card details on our servers.&nbsp;</li></ul>
            <p>While we will use all reasonable efforts to safeguard your personal data, you acknowledge that the use of the internet is not entirely secure and for this reason we cannot guarantee the security or integrity of any personal data that are transferred from you or to you via the internet. If you have any particular concerns about your information, please contact us (see ‘How can you contact us?’ below).</p>
            <p>Our Website may contain links to other websites of our partners, suppliers, advertisers or other approved third parties. If you follow a link to any of these websites, please note that these websites have (or should have) their own privacy policies. We do not accept any responsibility or liability for these policies or the way in which your personal data may be treated by these third parties. We recommend you check the privacy policy of any third party before you submit any personal data to their website.</p>
            <h2><strong>Transfers of your information out of the EEA</strong></h2>
            <p>We may need to transfer your personal data to countries which are located outside the European Economic Area (“<strong>EEA</strong>”), for the purpose of providing products or services to you. You may be located in a country outside of the EEA and therefore we may have no choice but to transfer your data outside of the EEA. Rest assured that any transfer of your personal data outside of the EEA will be subject to a GDPR-compliant guarantee (such as the EU-US Privacy Shield or a Model Contract Clause approved by the European Commission) that will safeguard your privacy rights and give you remedies in the unlikely event of a security breach.</p>
            <h2><strong>How long do we hold your data for?</strong></h2>
            <p>We only keep your personal data as long as necessary for the purpose for which it was obtained. After that period, we either: (1) anonymise the data if we still wish to use it for analytical purposes, or (2) pseudonymise the data if believe in good faith that we may need to process the data in the future for a legitimate purpose, or in all other cases (3) delete it completely from our servers.</p>
            <h2><strong>What rights do you have?</strong></h2>
            <h4>Right to request a copy of your information</h4>
            <p>You can request a copy of your information which we hold (this is known as a subject access request). If you would like a copy of some or it, please:</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below),</li><li>let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill will suffice), and</li><li>let us know the information you want a copy of, including any account or reference numbers, if you have them.</li></ul>
            <p>We will acknowledge receipt of your request and will respond within thirty (30) days. We will not charge you for providing the information.&nbsp;</p>
            <h4>Right to correct any mistakes in your information</h4>
            <p>You can require us to correct any mistakes in your information which we hold free of charge. If you would like to do this, please:</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below),</li><li>let us have enough information to identify you (e.g. account number, user name, registration details), and</li><li>let us know the information that is incorrect and what it should be replaced with.</li></ul>
            <p>We will acknowledge receipt of your request and will respond within thirty (30) days.</p>
            <h4>Right to ask us to stop contacting you with direct marketing</h4>
            <p>You can ask us to stop contacting you for direct marketing purposes. If you would like to do this, please:</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below). You can also click on the ‘unsubscribe’ button at the bottom of marketing emails from us,</li><li>let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill will suffice), and</li><li>let us know what method of contact you are not happy with if you are unhappy with certain ways of contacting you only (for example, you may be happy for us to contact you by email but not by telephone).</li></ul>
            <p>We will acknowledge receipt of your request and will respond within thirty (30) days.</p>
            <h4>Right to erasure&nbsp;</h4>
            <p>You can request that we delete all personal data relating to you free of charge. If you would like to do this, please:</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below), and</li><li>let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill will suffice), and</li><li>provide us with the justification for the erasure request (e.g. you are withdrawing your consent, you no longer believe that we should be processing the personal data for the original purpose for which it was obtained, the personal data is being unlawfully processed, there is a legal reason for erasure etc.).&nbsp;</li></ul>
            <p>We will acknowledge receipt of your request and will respond within thirty (30) days.</p>
            <h4>Right to Restrict Processing</h4>
            <p>You can request that we restrict processing of some of your personal data. If you would like to do this, please:</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below), and</li><li>let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill will suffice), and</li><li>provide us with details of what personal data you would like us to restrict the processing of (e.g. where you contest the accuracy of some personal data, we shall restrict the processing of it whilst its accuracy is verified).</li></ul>
            <p>We will acknowledge receipt of your request and will respond within thirty (30) days. If we agree to restrict the processing of the personal data before the thirty (30) day period, we will inform you as soon as we have put in place the restriction.</p>
            <h4>Right to Object</h4>
            <p>You can object to us processing any of your personal data. If you would like to do this, please:&nbsp;</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below), and</li><li>let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill will suffice), and</li><li>provide us with details of what personal data you object to us processing.</li></ul>
            <p>We will acknowledge receipt of your request and will respond within thirty (30) days.</p>
            <h4>Right to Data Portability</h4>
            <p>You can request that we provide some or all of your personal data we hold to a third party free of charge. If you would like to do this, please:</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below),&nbsp;</li><li>let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility or credit card bill will suffice), and</li><li>provide us with sufficient details of the third-party entity to which you would like your data transferred.</li></ul>
            <p>We will acknowledge receipt of your request and will provide your personal to the third-party entity in a commonly used machine-readable format within thirty (30) days providing you have provided us with sufficient information to do so. We will not charge you for this service.</p>
            <h4>Rights relating to automated decision making and profiling</h4>
            <p>We use software that automatically processes personal data for us. We ensure that processing using this software is fair and we implement all appropriate technical and organisational measures to ensure inaccuracies are minimised. If you are concerned about the use of such software, you have the right to ask for more details about the processing and request that we stop using the software to process your data. If you would like to do this, please:</p>
            <ul><li>email, call or write to us (see ‘How can you contact us?’ below),</li><li>let us have enough information to identify you (e.g. account number, user name, registration details), and</li><li>provide us with details of your concerns and the categories of personal data you believe are being processed by automated software.</li></ul>
            <p>We will acknowledge receipt of your request and will respond within thirty (30) days. Please note that if the automated processing is necessary for the performance of a contract between you and us, if you request that the software is no longer used to process your data, we may not be able to provide you with services any more.</p>
            <h4>Right to complain to the supervisory authority</h4>
            <p>If you are unhappy with the way in which we have dealt with a request you have made or you feel that we are not complying with this Privacy Policy in any way, you have the right to complain to the supervisory authority in the country in which you live. The supervisory authority in England and Wales is the Information Commissioners Office and details of how to contact them are available on their website: <a href="http://www.ico.org.uk">www.ico.org.uk</a>.&nbsp;</p>
            <h4>Time Extensions and Refusals</h4>
            <p>We reserve the right to extend the time period to respond to any of the requests listed above by up to sixty (60) days where a request is complex or a large number of requests are made. If we fail to respond to you by the deadline we set, you have a right to complain to the supervisory authority or seek a judicial remedy (see – ‘Right to complain to the supervisory authority’ above).</p>
            <p>We may also refuse a request where there are legitimate reasons to do so. These include, but are not limited to:</p>
            <ul><li>where a request is manifestly unfounded, excessive or repetitive; or</li><li>where personal data is being processed:<ul><li>in order to comply with a legal obligation;</li><li>in the public interest;</li><li>in the exercise or defence of a legal claim;</li><li>in the exercise of the right to freedom of expression and information.</li></ul></li></ul>
            <h2><strong>How to contact us</strong></h2>
            <p>Please contact us via <a href="mailto:help@editsquare.com">help@editsquare.com</a>. If you have any questions about this Privacy Policy or the information we hold about you, we will be delighted to assist.</p>
            <h2><strong>Changes to this Privacy Policy</strong></h2>
            <p>We may change this Privacy Policy from time to time. You should check this policy occasionally to ensure you are aware of the most recent version that will apply each time you access this Website.</p>

          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default PrivacyPolicy;
